import { useRouter } from "next/router";
import React from "react";

import Styles from "./FourZeroFour.styles";
import { FourZeroFourProps as Props } from "./FourZeroFour.types";
import Button from "components/global/Button/Button";
import useI18n from "hooks/useI18n";

import ErrorSVG from "../../../../public/assets/images/404/four-zero-four.svg";

const FourZeroFour: React.FC<Props> = props => {
  const router = useRouter();
  const t = useI18n();

  return (
    <Styles className="FourZeroFour">
      <main className="FourZeroFour__main">
        <div className="FourZeroFour__icon">
          <ErrorSVG />
        </div>
        <p className="FourZeroFour__info">{t.fourZeroFour.title}</p>
        <p className="FourZeroFour__description">
          {t.fourZeroFour.description}
        </p>
        <Button
          color="black"
          className="FourZeroFour__button"
          onClick={() => router.push("/")}
        >
          {t.fourZeroFour.button}
        </Button>
      </main>
    </Styles>
  );
};

FourZeroFour.defaultProps = {};

export default FourZeroFour;
