import React from "react";

import FourZeroFour from "components/fourZeroFour/FourZeroFour/FourZeroFour";

interface Props {}

const NotFound = (props: Props) => {
  return <FourZeroFour />;
};

export default NotFound;
